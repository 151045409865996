export const categories = {
  data: [
    {
      id: 1,
      name: 'Foods',
      slug: 'foods.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/prepared-foods.png',
        original: '/assets/images/category/prepared-foods.png',
      },
      icon: '/assets/images/category/prepared-foods.png',
    },
    {
      id: 2,
      name: 'Beverages',
      slug: 'beverages.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/baked-goods.png',
        original: '/assets/images/category/baked-goods.png',
      },
      icon: '/assets/images/category/baked-goods.png',
    },
    {
      id: 3,
      name: 'International Foods',
      slug: 'international-foods.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-int-foods.webp',
        original: '/assets/images/category/fsd-int-foods.webp',
      },
      icon: '/assets/images/category/fsd-int-foods.webp',
    },
    {
      id: 4,
      name: 'Disposables',
      slug: 'disposables.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-disposables.webp',
        original: '/assets/images/category/fsd-disposables.webp',
      },
      icon: '/assets/images/category/fsd-disposables.webp',
    },
    {
      id: 5,
      name: 'Janitorial Supplies',
      slug: 'janitorial-supplies.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-janitorial.webp',
        original: '/assets/images/category/fsd-janitorial.webp',
      },
      icon: '/assets/images/category/fsd-janitorial.webp',
    },
    {
      id: 6,
      name: 'Kitchen Smallware',
      slug: 'kitchen-smallware.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-kitchen.webp',
        original: '/assets/images/category/fsd-kitchen.webp',
      },
      icon: '/assets/images/category/fsd-kitchen.webp',
    },
    {
      id: 7,
      name: 'Dinnerware',
      slug: 'dinnerware.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-dinnerware.webp',
        original: '/assets/images/category/fsd-dinnerware.webp',
      },
      icon: '/assets/images/category/fsd-dinnerware.webp',
    },
    {
      id: 8,
      name: 'Kitchen Equipment',
      slug: 'kitchen-equipment.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-kitchen-equip.webp',
        original: '/assets/images/category/fsd-kitchen-equip.webp',
      },
      icon: '/assets/images/category/fsd-kitchen-equip.webp',
    },
    {
      id: 9,
      name: 'Health and Personal Care',
      slug: 'health-and-personal-care.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/fsd-health.webp',
        original: '/assets/images/category/fsd-health.webp',
      },
      icon: '/assets/images/category/fsd-health.webp',
    },
  ],
}

const homeAssetsCategoriesPath = '/assets/images/home-one/categories'

export const featuredCategories = {
  data: [
    {
      id: 1,
      name: 'Pre-Prepared Foods',
      slug: 'foods/pre-prepared-foods.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/prepared-food.png',
        original: '/assets/images/category/prepared-food.png',
      },
      icon: '/assets/images/category/prepared-food.png',
    },
    {
      id: 2,
      name: 'Baked Goods',
      slug: 'foods/baked-goods.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/baked-goods.png',
        original: '/assets/images/category/baked-goods.png',
      },
      icon: '/assets/images/category/baked-goods.png',
    },
    {
      id: 3,
      name: 'Desserts',
      slug: 'foods/desserts.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/dessert.png',
        original: '/assets/images/category/dessert.png',
      },
      icon: '/assets/images/category/dessert.png',
    },
    {
      id: 4,
      name: 'Condiments and Dippings',
      slug: 'foods/condiments-dippings.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/condiments.png',
        original: '/assets/images/category/condiments.png',
      },
      icon: '/assets/images/category/condiments.png',
    },
    {
      id: 5,
      name: 'Bases',
      slug: 'foods/bases.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/bases.png',
        original: '/assets/images/category/bases.png',
      },
      icon: '/assets/images/category/bases.png',
    },
    {
      id: 6,
      name: 'Spices & Seasonings',
      slug: 'foods/spices-seasonings.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/spices.png',
        original: '/assets/images/category/spices.png',
      },
      icon: '/assets/images/category/spices.png',
    },
    {
      id: 7,
      name: 'Sauces',
      slug: 'foods/sauces.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/sauces.png',
        original: '/assets/images/category/sauces.png',
      },
      icon: '/assets/images/category/sauces.png',
    },
    {
      id: 8,
      name: 'Baking Ingredients',
      slug: 'foods/baking-ingredients.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/baking.png',
        original: '/assets/images/category/baking.png',
      },
      icon: '/assets/images/category/baking.png',
    },
    {
      id: 9,
      name: 'Snacks',
      slug: 'foods/snacks.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/snacks.png',
        original: '/assets/images/category/snacks.png',
      },
      icon: '/assets/images/category/snacks.png',
    },
    {
      id: 10,
      name: 'Cheese',
      slug: 'foods/cheese.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/cheese.png',
        original: '/assets/images/category/cheese.png',
      },
      icon: '/assets/images/category/cheese.png',
    },
    {
      id: 10,
      name: 'Disposable Cups',
      slug: 'disposables/disposable-cups.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/disposable-cups.png',
        original: '/assets/images/category/disposable-cups.png',
      },
      icon: '/assets/images/category/disposable-cups.png',
    },
    {
      id: 10,
      name: 'Oils and Fats',
      slug: 'foods/oils-fats.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/oils.png',
        original: '/assets/images/category/oils.png',
      },
      icon: '/assets/images/category/oils.png',
    },
  ],
}

export const footerCategories = {
  data: [
    {
      id: 1,
      name: 'Pre-Prepared Foods',
      slug: 'foods/pre-prepared-foods.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/prepared-food.png',
        original: '/assets/images/category/prepared-food.png',
      },
      icon: '/assets/images/category/prepared-food.png',
    },
    {
      id: 2,
      name: 'Baked Goods',
      slug: 'foods/baked-goods.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/baked-goods.png',
        original: '/assets/images/category/baked-goods.png',
      },
      icon: '/assets/images/category/baked-goods.png',
    },
    {
      id: 3,
      name: 'Desserts',
      slug: 'foods/desserts.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/dessert.png',
        original: '/assets/images/category/dessert.png',
      },
      icon: '/assets/images/category/dessert.png',
    },
    {
      id: 4,
      name: 'Condiments and Dippings',
      slug: 'foods/condiments-dippings.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/condiments.png',
        original: '/assets/images/category/condiments.png',
      },
      icon: '/assets/images/category/condiments.png',
    },
    {
      id: 5,
      name: 'Bases',
      slug: 'foods/bases.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/bases.png',
        original: '/assets/images/category/bases.png',
      },
      icon: '/assets/images/category/bases.png',
    },
    {
      id: 6,
      name: 'Spices & Seasonings',
      slug: 'foods/spices-seasonings.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/spices.png',
        original: '/assets/images/category/spices.png',
      },
      icon: '/assets/images/category/spices.png',
    },
    {
      id: 7,
      name: 'Sauces',
      slug: 'foods/sauces.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/sauces.png',
        original: '/assets/images/category/sauces.png',
      },
      icon: '/assets/images/category/sauces.png',
    },
    {
      id: 8,
      name: 'Baking Ingredients',
      slug: 'foods/baking-ingredients.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/baking.png',
        original: '/assets/images/category/baking.png',
      },
      icon: '/assets/images/category/baking.png',
    },
    {
      id: 9,
      name: 'Snacks',
      slug: 'foods/snacks.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/snacks.png',
        original: '/assets/images/category/snacks.png',
      },
      icon: '/assets/images/category/snacks.png',
    },
    {
      id: 10,
      name: 'Cheese',
      slug: 'foods/cheese.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/cheese.png',
        original: '/assets/images/category/cheese.png',
      },
      icon: '/assets/images/category/cheese.png',
    },
    {
      id: 10,
      name: 'Disposable Cups',
      slug: 'disposables/disposable-cups.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/disposable-cups.png',
        original: '/assets/images/category/disposable-cups.png',
      },
      icon: '/assets/images/category/disposable-cups.png',
    },
    {
      id: 10,
      name: 'Oils and Fats',
      slug: 'foods/oils-fats.html',
      image: {
        id: 1,
        thumbnail: '/assets/images/category/oils.png',
        original: '/assets/images/category/oils.png',
      },
      icon: '/assets/images/category/oils.png',
    },
  ],
}
