import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { PropsWithChildren } from 'react'

interface LinkProps extends NextLinkProps {
  className?: string
  target?: string
}

export default function Link({
  href,
  children,
  className,
  target,
  ...props
}: PropsWithChildren<LinkProps>) {
  return (
    <NextLink href={href} legacyBehavior>
      <a className={className} target={target} {...props}>
        {children}
      </a>
    </NextLink>
  )
}
