import { useState, useEffect, FormEvent, forwardRef } from 'react'
import cn from 'classnames'
import useFreezeBodyScroll from '@utils/use-freeze-body-scroll'
import { useUI } from '@contexts/ui.context'
import {
  InstantSearch,
  SearchBox,
  connectRefinementList,
  Hits,
  Stats,
  Pagination,
  Highlight,
  NumericMenu,
} from 'react-instantsearch-dom'
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter'
import uniqBy from 'lodash.uniqby'

import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import ProductCardAlpine from '@components/product/product-cards/product-card-alpine'
import { convertProduct } from '@framework/utils/convert-product'
import { Product } from '@framework/types'
import { TYPESENSE_CONFIG } from '@framework/typesense'
import { useRouter as useRouterNavigation } from 'next/navigation'
import { getCollectionName } from '@utils/helper'

type Props = {
  className?: string
  searchId?: string
  variant?: 'border' | 'fill'
}

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: TYPESENSE_CONFIG,
  additionalSearchParameters: {
    query_by:
      'name,upc,gtin,sku,mpn,category,category_l4,category_l3,category_l2,category_l1,manufacturer,brand,description',
    num_typos: 3,
    filter_by: 'is_active: true',
    per_page: 12,
  },
})

const RefinementList = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  title,
  position,
}: any) => (
  <div
    className={`border ${
      position === 'first'
        ? 'rounded-t-lg'
        : position === 'last'
        ? 'rounded-b-lg border-t-0'
        : 'border-t-0'
    } border-neutral-200 bg-white`}
  >
    <h2 className="mb-0" id={`heading${title}`}>
      <button
        className="group relative flex w-full items-center rounded-none border-0 px-5 py-4 text-left capitalize text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)]"
        type="button"
        data-te-collapse-init
        data-te-collapse-collapsed
        data-te-target={`#collapse${title}`}
        aria-expanded="false"
        aria-controls={`collapse${title}`}
      >
        {title}
        <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
      </button>
    </h2>
    <div
      id={`collapse${title}`}
      className="!visible hidden"
      data-te-collapse-item
      aria-labelledby={`heading${title}`}
      data-te-parent="#search_filters"
    >
      <div className="px-5 py-4">
        <ul>
          {items
            .filter((i: any) => i.label !== '')
            .map((item: any) => (
              <li key={item.label} className="cat_item">
                <a
                  href={createURL(item.value)}
                  style={{ fontWeight: item.isRefined ? 'bold' : '' }}
                  onClick={(event) => {
                    event.preventDefault()
                    refine(item.value)
                  }}
                >
                  {isFromSearch ? (
                    <>
                      {/* @ts-ignore */}
                      <Highlight attribute="label" hit={item} />
                    </>
                  ) : (
                    <span
                      className="name"
                      dangerouslySetInnerHTML={{
                        __html: item.label.toLowerCase(),
                      }}
                    ></span>
                  )}{' '}
                  <span className="count">{item.count}</span>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  </div>
)

const CustomRefinementList = connectRefinementList(RefinementList)

const Hit = ({ hit }: any) => {
  return (
    <ProductCardAlpine
      key={`product--key-${hit.id}`}
      product={convertProduct(hit) as Product}
      className="searched-products"
    />
  )
}

const deduplicate = (items: any) => {
  return uniqBy(items, (item) => (item as any).attribute)
}

const Search = forwardRef<HTMLDivElement, Props>(
  (
    {
      className = 'md:w-[730px] 2xl:w-[800px]',
      searchId = 'search',
      variant = 'border',
    },
    ref,
  ) => {
    const {
      displayMobileSearch,
      closeMobileSearch,
      displaySearch,
      closeSearch,
    } = useUI()
    const { t } = useTranslation('common')
    const { locale } = useRouter()
    const router = useRouter()
    const [searchText, setSearchText] = useState('')
    const [resultsClass, setResultsClass] = useState('hidden')
    const [showResults, setShowResults] = useState<boolean>(false)
    const [inputFocus, setInputFocus] = useState<boolean>(false)
    const [placeholder, setPlaceholder] = useState(t('query_placeholder'))

    const updatePlaceholder = () => {
      if (window.innerWidth <= 480) {
        setPlaceholder('Search FoodServiceDirect.ca') // Smaller placeholder for small screens
      } else {
        setPlaceholder(t('query_placeholder')) // Default for larger screens
      }
    }

    useEffect(() => {
      // Set placeholder based on initial screen size
      updatePlaceholder()

      // Add event listener to handle window resizing
      window.addEventListener('resize', updatePlaceholder)

      // Clean up event listener when component unmounts
      return () => {
        window.removeEventListener('resize', updatePlaceholder)
      }
    }, [])

    useFreezeBodyScroll(
      inputFocus === true || displaySearch || displayMobileSearch,
    )
    useEffect(() => {
      setResultsClass(
        !showResults
          ? 'hidden'
          : 'search-results overflow-auto h-full p-1 pb-40',
      )
      router.events.on('routeChangeComplete', () => setShowResults(false))
      const init = async () => {
        const { Collapse, initTE } = await import('tw-elements')
        initTE({ Collapse })
      }
      init()
    }, [showResults, searchText, router])

    const handleAutoSearch = (e: FormEvent<HTMLInputElement>) => {
      setSearchText(e.currentTarget.value)
      setShowResults(
        e.currentTarget.value.length > 1 &&
          e.currentTarget.value !== e.currentTarget.placeholder,
      )
      if (
        e.currentTarget.value === '' ||
        e.currentTarget.value === e.currentTarget.placeholder
      )
        clear()
    }
    const routerN = useRouterNavigation()

    const clear = () => {
      setSearchText('')
      setInputFocus(false)
      closeMobileSearch()
      closeSearch()
    }

    const handleSubmit = (e: FormEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (searchText.length) {
        window.location.href = `/search?q=${searchText}`
      }
    }

    return (
      <>
        {/* @ts-ignore */}
        <InstantSearch
          indexName={getCollectionName(locale)}
          searchClient={typesenseInstantsearchAdapter.searchClient}
        >
          <div
            ref={ref}
            className={cn(
              'w-full transition-all duration-200 ease-in-out',
              className,
            )}
          >
            <div
              className={cn(
                'overlay cursor-pointer invisible w-full h-full opacity-0 flex top-0 ltr:left-0 rtl:right-0 transition-all duration-300 fixed',
                {
                  open: displayMobileSearch,
                  'input-focus-overlay-open': inputFocus === true,
                  'open-search-overlay': displaySearch,
                },
              )}
              onClick={() => clear()}
            />
            {/* End of overlay */}

            <div className="relative z-30 flex flex-col justify-center w-full shrink-0">
              <div className="flex flex-col w-full mx-auto">
                <label
                  id="headerSearchLabel"
                  htmlFor="headerSearch"
                  className="pl-1"
                >
                  {t('query_placeholder')}
                </label>
                {/* @ts-ignore */}
                <SearchBox
                  translations={{
                    submitTitle: t('submit_query'),
                    resetTitle: t('clear_query'),
                    placeholder: t('query_placeholder'),
                  }}
                  searchId={searchId}
                  name="headerSearch"
                  onChange={handleAutoSearch}
                  onSubmit={handleSubmit}
                  onReset={() => setResultsClass('hidden')}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13 && window && e.target.value.length)
                      window.location.href = `/search?q=${e.target.value}` //routerN.push(`/search?q=${e.target.value}`);
                  }}
                />
              </div>
              {/* End of searchbox */}

              <div className={resultsClass}>
                {/* @ts-ignore */}
                <Stats />
                <div
                  className="search_filters"
                  id="search_filters"
                  style={{ float: 'left' }}
                >
                  {/* @ts-ignore */}
                  <CustomRefinementList
                    attribute="category"
                    /* @ts-ignore */
                    title={t('text-categories')}
                    position="first"
                    searchable={false}
                  />

                  {/* @ts-ignore */}
                  <CustomRefinementList
                    attribute="manufacturer"
                    /* @ts-ignore */
                    title={t('text-manufacturers')}
                    position="middle"
                    searchable={false}
                  />

                  {/* @ts-ignore */}
                  <CustomRefinementList
                    attribute="brand"
                    /* @ts-ignore */
                    title={t('text-brands')}
                    position="middle"
                    searchable={false}
                  />

                  <div
                    className={`border rounded-b-lg border-t-0 border-neutral-200 bg-white`}
                  >
                    <h2 className="mb-0" id={`headingPrice`}>
                      <button
                        className="group relative flex w-full items-center rounded-none border-0 px-5 py-4 text-left capitalize text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)]"
                        type="button"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target={`#collapsePrice`}
                        aria-expanded="false"
                        aria-controls={`collapsePrice`}
                      >
                        {t('text-price')}
                        <span className="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                            />
                          </svg>
                        </span>
                      </button>
                    </h2>
                    <div
                      id={`collapsePrice`}
                      className="!visible hidden"
                      data-te-collapse-item
                      aria-labelledby={`headingPrice`}
                      data-te-parent="#search_filters"
                    >
                      <div className="px-5 py-4">
                        {/* price(Low:[0, 100], Mid:[100, 150], High:[150, 10000]) */}
                        {/* @ts-ignore */}
                        <NumericMenu
                          container="#search_filters"
                          attribute="price"
                          items={[
                            { label: t('text-see-all') },
                            { label: `${t('text-less-than')} 50$`, end: 50 },
                            {
                              label: t('text-between') + ' 50$ - 100$',
                              start: 50,
                              end: 100,
                            },
                            {
                              label: t('text-between') + ' 100$ - 200$',
                              start: 100,
                              end: 200,
                            },
                            {
                              label: t('text-between') + ' 200$ - 300$',
                              start: 200,
                              end: 300,
                            },
                            {
                              label: t('text-greater-than') + ' 1000$',
                              start: 1000,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* @ts-ignore */}
                <Hits hitComponent={Hit} clickAction={() => clear()} />
                {/* @ts-ignore */}
                <Pagination />
              </div>

              {/* End of search result */}
            </div>
          </div>
        </InstantSearch>
      </>
    )
  },
)

Search.displayName = 'Search'
export default Search
