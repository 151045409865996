import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import Input from '@components/ui/form/input'
import { useTranslation } from 'next-i18next'
import EmailIcon from '@components/icons/email-icon'
import SendIcon from '@components/icons/send-icon'
import Text from '@components/ui/text'
import Heading from '@components/ui/heading'
import { useRouter } from 'next/router'
import { getDirection } from '@utils/get-direction'
import cn from 'classnames'
import cartServices from '@services/cartservices'
import { ImSpinner2 } from 'react-icons/im'
interface Props {
  className?: string
}
interface NewsLetterFormValues {
  email: string
}
const defaultValues = {
  email: '',
}
const WidgetSubscription: FC<Props> = ({ className }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewsLetterFormValues>({
    defaultValues,
  })
  const [loading, setLoading] = useState(false)
  const [subscriptionSuccess, setSubscriptionSuccess] = useState<string>('')

  async function onSubmit(values: NewsLetterFormValues, e: any) {
    try {
      setLoading(true)
      let response: any = await cartServices.subscribeNewsLetter({
        email: values.email,
      })
      if (response) {
        setLoading(false)
        // show success message
        setSubscriptionSuccess(response?.msg)

        // remove success message after 3 seconds
        setTimeout(() => {
          setSubscriptionSuccess('')
        }, 5000)

        // reset form after submit
        e.target.reset()
      }
    } catch (error) {
      console.error('Subscribe Error', error)
      setLoading(false)
    }
  }

  const { locale } = useRouter()
  const dir = getDirection(locale)
  return (
    <div className={cn('flex flex-col', className)}>
      {/* <Heading variant="mediumHeading" className="mb-4 lg:mb-6 lg:pb-0.5">
        {t('footer:widget-title-subscribe')}
      </Heading>

      <Text className="lg:-mt-1 max-w-[400px]">
        {t('footer:text-subscribe')}
      </Text> */}
      <form
        noValidate
        className="relative mt-5 max-w-[400px]"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <span className="flex items-center absolute ltr:left-0 rtl:right-0 top-0 h-12 px-3.5 transform">
          <EmailIcon className="w-4 2xl:w-[18px] h-4 2xl:h-[18px]" />
        </span> */}
        <label id="subscriptionFooterLabel" htmlFor="subscription-email">
          {t('forms:placeholder-email-subscribe') as string}
        </label>
        <Input
          placeholder={t('forms:placeholder-email-subscribe') as string}
          type="email"
          id="subscription-email"
          maxLength={50}
          variant="solid"
          className="w-full"
          inputClassName="w-full bg-brand-darkblue text-white pb-1 px-3 py-2 mb-6 text-sm border-t-0 border-l-0 border-r-0 border-b-1 border-white focus:border-white placeholder:text-white focus:ring-0"
          {...register('email', {
            required: `${t('forms:email-required')}`,
            pattern: {
              value:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: `${t('forms:email-error')}`,
            },
          })}
          error={errors.email?.message}
          style={{ paddingRight: '2.5em' }}
          data-testid="footer-input-newsletter-email"
        />
        {!errors.email && subscriptionSuccess && (
          <p className="my-2 text-13px text-white">{subscriptionSuccess}</p>
        )}
        <button
          className="absolute ltr:right-0 rtl:left-0 top-0 hover:opacity-80 focus:outline-none h-12 px-3 lg:px-3.5 transform scale-90 2xl:scale-100"
          aria-label="Subscribe Button"
          data-testid="footer-btn-newsletter-email"
        >
          {loading ? (
            <ImSpinner2 className="w-5 h-5 animate-spin ltr:-mr-1 rtl:-ml-1 ltr:ml-3 rtl:mr-3 " />
          ) : (
            <SendIcon
              className={`w-[25px] 2xl:w-5 h-[25px] 2xl:h-5 ${
                dir === 'rtl' && 'transform rotate-180'
              }`}
            />
          )}
        </button>
      </form>
    </div>
  )
}

export default WidgetSubscription
