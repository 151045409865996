import { productPlaceholder } from '@assets/placeholders'
import { useModalAction } from '@components/common/modal/modal.context'
import { Eye } from '@components/icons/eye-icon'
import Image from '@components/ui/image'
import { useCart } from '@contexts/cart/cart.context'
import usePrice from '@framework/product/use-price'
import { Product } from '@framework/types'
import useWindowSize from '@utils/use-window-size'
import { convertProduct } from '@framework/utils/convert-product'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { ROUTES } from '@utils/routes'
import { FC } from 'react'
import { getCurrentPrice, getOldPrice } from '@utils/helper'
import { CommonProductTitle } from '@utils/common-product-title'
import Link from 'next/link'
const AddToCart = dynamic(() => import('@components/product/add-to-cart'), {
  ssr: false,
})

interface ProductProps {
  product: Product
  className?: string
  index: number
  sectionHeading: string
}
function RenderPopupOrAddToCart({ props }: { props: Object }) {
  let { data, index, sectionHeading }: any = props
  const { t: translate } = useTranslation('common')
  const { id, quantity, product_type, is_active } = convertProduct(data) ?? {}
  const { width } = useWindowSize()
  const { openModal } = useModalAction()
  const { isInCart, isInStock } = useCart()
  const iconSize = width! > 1024 ? '19' : '17'
  const outOfStock = isInCart(id) && !isInStock(id)
  function handlePopupView() {
    openModal('PRODUCT_VIEW', data)
  }
  if (Number(quantity) < 1 || outOfStock) {
    return (
      <span className="text-[11px] md:text-xs font-bold text-brand-light uppercase inline-block bg-brand-danger rounded-full px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1">
        {translate('text-out-stock')}
      </span>
    )
  }
  if (!is_active) {
    return (
      <span className="text-[11px] md:text-xs font-bold text-brand-light uppercase inline-block bg-brand-danger rounded-full px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1">
        {translate('text-out-stock')}
      </span>
    )
  }
  if (product_type === 'variable') {
    return (
      <button
        className="inline-flex items-center justify-center w-8 h-8 text-4xl rounded-full bg-brand lg:w-10 lg:h-10 text-brand-light focus:outline-none focus-visible:outline-none"
        aria-label="Count Button"
        onClick={handlePopupView}
      >
        <Eye width={iconSize} height={iconSize} opacity="1" />
      </button>
    )
  }
  return (
    <AddToCart
      data={data}
      variant="mercury"
      index={index}
      sectionHeading={sectionHeading}
    />
  )
}
const ProductCardAlpine: FC<ProductProps> = ({
  product,
  className,
  index,
  sectionHeading,
}) => {
  const {
    name,
    image,
    product_type,
    brand,
    case_quantity,
    gallery,
    slug,
    special_price,
    special_from_date,
    special_to_date,
    price,
  } = convertProduct(product) ?? {}
  const { openModal } = useModalAction()
  const { t: translate } = useTranslation('common')

  function handlePopupView() {
    openModal('PRODUCT_VIEW', product)
  }

  const priceDetails = {
    special_price,
    special_from_date,
    special_to_date,
    price,
  }

  const currentPrice = getCurrentPrice(priceDetails)
  const oldPrice = getOldPrice(priceDetails)

  const { price: formattedCurrentPrice } = usePrice({
    amount: currentPrice,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const { price: formattedOldPrice } = usePrice({
    amount: oldPrice,
    currencyCode: process.env.NEXT_PUBLIC_CURRENCY_CODE || 'USD',
  })

  const productUrl = `${process.env.NEXT_PUBLIC_WEBSITE_URL}/${slug}`
  return (
    <article
      className={cn(
        'flex flex-col group overflow-hidden rounded-md cursor-pointer transition-all duration-300 shadow-card hover:shadow-cardHover relative h-full',
        className,
      )}
      onClick={handlePopupView}
      title={name}
    >
      <div className="relative shrink-0">
        <div
          className="flex overflow-hidden max-w-[230px] max-h-[200px] mx-auto transition duration-200 ease-in-out transform group-hover:scale-105 relative"
          style={{ aspectRatio: '23 / 20' }}
        >
          <Image
            src={
              (product['image.thumbnail'] || gallery?.[0]?.thumbnail) ??
              productPlaceholder
            }
            alt={name || 'Product Image'}
            fill={true}
            quality={100}
            priority
            className="object-cover bg-fill-thumbnail"
          />
        </div>
        <div className="w-full h-full absolute top-0 pt-2.5 md:pt-3.5 px-3 md:px-4 lg:px-[18px] z-10 -mx-0.5 sm:-mx-1">
          <div className={`block product-count-button-position`}>
            <RenderPopupOrAddToCart
              props={{ data: product, index, sectionHeading }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col px-3 md:px-4 lg:px-[18px] pb-5 lg:pb-6 lg:pt-1.5 h-full">
        <div className="flex flex-col mb-1 lg:mb-1.5 -mx-1">
          {oldPrice && (
            <del className="mx-1 text-sm text-brand-dark text-opacity-70">
              {formattedOldPrice}
            </del>
          )}
          <span className="inline-block mx-1 text-sm font-semibold sm:text-15px lg:text-base text-brand-dark">
            {formattedCurrentPrice}
          </span>
        </div>
        <h2 className="text-brand-dark hover:underline hover:font-semibold text-13px sm:text-sm lg:text-15px leading-5 sm:leading-6 mb-1.5 capitalize">
          <Link href={productUrl} onClick={(e: any) => e.stopPropagation()}>
            {CommonProductTitle(product)}
          </Link>
        </h2>
        <div className="mt-auto text-13px sm:text-sm">
          {case_quantity
            ? `${case_quantity} ${translate('text-per-case')}`
            : ''}
        </div>
      </div>
    </article>
  )
}

export default ProductCardAlpine
