import Widgets from '@components/layout/footer/widget/widget'
import WidgetsSubscription from '@components/layout/footer/widget/widget-subscription'
import Copyright from '@components/layout/footer/copyright'
import { footer } from './data'
import { FC } from 'react'
import Link from 'next/link'
import Image from '@components/ui/image'

import cn from 'classnames'
import {
  footerCategories,
  featuredCategories,
  categories,
} from '@utils/main-categories'
import { useTranslation } from 'next-i18next'

interface FooterProps {
  variant?: 'default' | 'medium'
}

const { widgets, payment } = footer

const HomeFooter: FC<FooterProps> = ({ variant = 'default' }) => {
  const { t: translate } = useTranslation(['common', 'home', 'footer', 'menu'])

  return (
    <footer className="home-footer">
      <div
        className="flex flex-col pt-[80px] bg-brand-darkblue text-white min-h-[677px]"
        style={{ color: 'white' }}
      >
        <div
          className="container-- mx-auto px-4"
          style={{ margin: '0 auto', flexGrow: 1 }}
        >
          <div className="flex xs:flex-col-- xsp:flex-row flex-wrap text-center-- sm:text-left sm:justify-center md:justify-around gap-10 xsp:gap-11 lg:gap-[23px] lgp:gap-[51px] xl:gap-[90px] xlp:gap-[100px] 1xl:gap-[150px] 2xl:gap-[180px] 3xl:gap-[250px] 4xl:gap-[250px] xs:zoom-67 xsp:zoom-90 4xl:zoom-100">
            {/* FoodServiceDirect Column */}
            <div className="mt-10-- md:mt-0">
              <h3 className="font-poppins font-medium text-[1.5rem] mb-6">
                FoodServiceDirect
              </h3>
              <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                <li>
                  <a
                    href="/about-us"
                    data-testid="footer-link-about-us"
                    className="hover:text-gray-300"
                  >
                    {translate('link-about-us', { ns: 'footer' })}
                  </a>
                </li>

                <li>
                  <a
                    href="/faq"
                    data-testid="footer-link-faq"
                    className="hover:text-gray-300"
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
            {/* Categories Column */}
            <div className="mt-10-- md:mt-0">
              <h3 className="font-poppins font-medium text-[1.5rem] mb-6">
                {translate('menu-categories', { ns: 'menu' })}
              </h3>
              <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                {categories.data.map((category, index) => (
                  <li key={index}>
                    <a
                      href={`/${category.slug}`}
                      data-testid={`footer-link-category-${category.slug.replace(
                        '.html',
                        '',
                      )}`}
                      className="hover:text-gray-300"
                    >
                      {/* {category.name} */}
                      {translate(
                        `categories:${category.name.toLocaleLowerCase()}`,
                      )}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* Let Us Help You Column */}
            <div className="mt-10-- md:mt-0 max-w-[224px]">
              <h3 className="font-poppins font-medium text-[1.5rem] mb-6">
                {translate('menu-let-us-help-you', { ns: 'menu' })}
              </h3>
              <ul className="space-y-2 font-poppins font-medium text-[1.125rem]">
                <li>
                  <a
                    href="/return"
                    className="hover:text-gray-300"
                    data-testid="footer-link-returns-and-replacements"
                  >
                    {translate('link-returns-and-replacements', {
                      ns: 'footer',
                    })}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://support.foodservicedirect.com/hc/en-us/requests/new"
                    className="hover:text-gray-300"
                    data-testid="footer-link-contact-us"
                  >
                    {translate('link-contact-us', { ns: 'footer' })}
                  </a>
                </li>
              </ul>
            </div>
            {/* Newsletter Signup */}
            <div className="w-auto max-w-[100%] sm:max-w-[411px]">
              <div className="font-poppins font-medium text-[1.125rem] mt-[66px] mb-1">
                {translate('text-newletter-info')}
              </div>

              <WidgetsSubscription />

              <h3 className="font-poppins font-medium text-[1.5rem] mb-4">
                {translate('menu-questions', { ns: 'menu' })}?
              </h3>

              <div className="flex items-center flex-wrap gap-y-4 justify-center sm:justify-start">
                <Link
                  target="_blank"
                  href={`https://support.foodservicedirect.com/hc/en-us/requests/new`}
                  className={cn(
                    'text-brand-darkblue px-[24px] py-2 mr-5 bg-white rounded border text-base font-medium leading-6 text-left',
                  )}
                  data-testid="footer-btn-contact-us"
                >
                  {translate('text-contact-us')}
                </Link>

                <Link
                  href={`/faq`}
                  className={cn(
                    'px-[24px] py-2 bg-brand rounded border text-base font-medium leading-6 text-left',
                  )}
                  data-testid="footer-btn-faq"
                >
                  {translate('text-visit-faqs')}
                </Link>
              </div>
              {/* </form> */}

              <div className="flex items-center justify-center gap-6 max-w-[320px] my-10 mx-auto sm:mx-0 ">
                <div className="flex w-[42px] h-[40px] relative overflow-hidden">
                  <Link
                    href={`https://www.facebook.com/people/Food-Service-Direct-Canada/61550110816189/`}
                    data-testid="footer-link-social-facebook"
                  >
                    <Image
                      src={'/assets/images/landing-one/facebook-icon.svg'}
                      alt={'facebook'}
                      fill={true}
                      quality={100}
                      loading="lazy"
                      className="object-cover"
                    />
                  </Link>
                </div>

                <div className="flex w-[42px] h-[40px] relative overflow-hidden">
                  <Link
                    href={`https://www.youtube.com/channel/UC61Ow7SDQ1PIBRalrbNl4EA`}
                    data-testid="footer-link-social-youtube"
                  >
                    <Image
                      src={'/assets/images/landing-one/youtube-icon.svg'}
                      alt={'youtube'}
                      fill={true}
                      quality={100}
                      loading="lazy"
                      className="object-cover"
                    />
                  </Link>
                </div>

                <div className="flex w-[42px] h-[40px] relative overflow-hidden">
                  <Link
                    href={`https://www.pinterest.com/foodservicedirect/`}
                    data-testid="footer-link-social-pinterest"
                  >
                    <Image
                      src={'/assets/images/landing-one/pinterest-icon.svg'}
                      alt={'pinterest'}
                      fill={true}
                      quality={100}
                      loading="lazy"
                      className="object-cover"
                    />
                  </Link>
                </div>

                <div className="flex w-[42px] h-[40px] relative overflow-hidden">
                  <Link
                    href={`https://twitter.com/foodsrvcdirect`}
                    data-testid="footer-link-social-twitter"
                  >
                    <Image
                      src={'/assets/images/landing-one/twitter-icon.svg'}
                      alt={'twitter'}
                      fill={true}
                      quality={100}
                      loading="lazy"
                      className="object-cover"
                    />
                  </Link>
                </div>

                <div className="flex w-[42px] h-[40px] relative overflow-hidden">
                  <Link
                    href={`https://www.instagram.com/foodservicedirectcanada/`}
                    data-testid="footer-link-social-instagram"
                  >
                    <Image
                      src={'/assets/images/landing-one/instagram-icon.svg'}
                      alt={'instagram'}
                      fill={true}
                      quality={100}
                      loading="lazy"
                      className="object-cover"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col-reverse lg:flex-row flex-wrap-reverse gap-2 px-6 items-center justify-around mx-auto bg-white text-brand-darkblue py-[11px] w-full mt-auto">
          <div className="flex flex-col sm:flex-row items-center gap-6 xs:pb-20 lg:!pb-0">
            <div className="flex w-[47px] h-[48px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/paypal-icon.svg'}
                alt={'paypal'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail"
              />
            </div>

            <div className="flex w-[47px] h-[29px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/mastercard-icon.svg'}
                alt={'mastercard'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail"
              />
            </div>

            <div className="flex w-[48px] h-[48px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/visa-icon.svg'}
                alt={'visa'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail"
              />
            </div>

            <div className="flex w-[43px] h-[43px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/discover-icon.svg'}
                alt={'discover'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail"
              />
            </div>

            <div className="flex w-[87px] h-[26px] relative overflow-hidden">
              <Image
                src={'/assets/images/landing-one/securetrust-icon.svg'}
                alt={'securetrust'}
                fill={true}
                quality={100}
                loading="lazy"
                className="object-cover bg-fill-thumbnail"
              />
            </div>
          </div>

          <div className="flex">
            <ul className="flex flex-col sm:flex-row flex-wrap gap-x-7 gap-y-6 my-6 lg:my-0 px-6 lg:px-0 font-poppins text-base font-medium text-center sm:text-left pt-3 lg:pt-0 justify-center">
              <li>
                <Link
                  href={`/terms`}
                  className={cn('')}
                  data-testid="footer-link-terms-of-use"
                >
                  {translate('text-terms-of-use')}
                </Link>
              </li>
              <li>
                <Link
                  href={`/return`}
                  className={cn('')}
                  data-testid="footer-link-return-policy"
                >
                  {translate('text-return-policy')}
                </Link>
              </li>
              <li>
                <Link
                  href={`/privacy`}
                  className={cn('')}
                  data-testid="footer-link-privacy-policy"
                >
                  {translate('text-privacy-policy')}
                </Link>
              </li>
              <li> {translate('text-all-rights')}</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default HomeFooter
