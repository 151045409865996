import { FC, SVGAttributes } from 'react'

const MenuIcon: FC<SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="14"
      viewBox="0 0 25.567 18"
      {...props}
    >
      <g transform="translate(-776 -462)">
        <rect
          data-name="Rectangle 941"
          width="12.749"
          height="2.499"
          rx="1.25"
          transform="translate(776 462)"
          fill="currentColor"
        ></rect>
        <rect
          data-name="Rectangle 942"
          width="25.567"
          height="2.499"
          rx="1.25"
          transform="translate(776 469.75)"
          fill="currentColor"
        ></rect>
        <rect
          data-name="Rectangle 943"
          width="17.972"
          height="2.499"
          rx="1.25"
          transform="translate(776 477.501)"
          fill="currentColor"
        ></rect>
      </g>
    </svg>
  )
}

export default MenuIcon
