const SendIcon = ({
  color = '#02B290',
  width = '20',
  height = '20',
  className = '',
}) => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.29633 0.756957L14.068 6.449C14.1466 6.47556 14.2155 6.52524 14.2655 6.59154C14.3155 6.65784 14.3444 6.73765 14.3483 6.82052C14.3522 6.90339 14.331 6.98544 14.2875 7.05592C14.2439 7.1264 14.1801 7.18202 14.1042 7.21549L1.31542 12.94C1.24197 12.973 1.16037 12.9837 1.08077 12.9707C1.00116 12.9578 0.927054 12.9218 0.867637 12.8671C0.80822 12.8125 0.766113 12.7416 0.746543 12.6634C0.726973 12.5851 0.730802 12.5029 0.757554 12.4269L2.27113 8.06673L9.12909 6.85865L2.28399 5.63271L0.741403 1.26853C0.714704 1.19267 0.710779 1.1107 0.730114 1.03275C0.749448 0.954796 0.791195 0.88427 0.850205 0.82987C0.909214 0.77547 0.982904 0.739578 1.06218 0.726621C1.14146 0.713664 1.22286 0.72421 1.29633 0.756957Z"
        fill="white"
      />
    </svg>
  )
}

export default SendIcon
